<template>
  <div class="info-popover">
    <div
      id="ref"
      aria-describedby="pop"
    >
      <span
        ref="target"
        class="icon icon-center mdi mdi-information-outline"
        :class="iconClass"
        @click="toggleVisibility"
        @mouseenter="show"
      />
    </div>
    <div
      v-show="visible"
      id="pop"
      ref="popover"
      role="tooltip"
      class="panel"
      @mouseleave="hide"
    >
      <div
        v-if="title"
        class="panel-heading"
      >
        {{ title }}
        <span
          class="icon close-icon icon-center mdi mdi-close"
          @click="hide"
        />
      </div>
      <div class="panel-block">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Popper from 'popper.js';
  import {computed, onMounted, ref, watch} from 'vue';

  interface Props {
    title: string;
    iconColor?: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    iconColor: 'info',
  });

  const iconClass = computed(() => ({
    [`has-text-${props.iconColor}`]: true,
  }));

  const target = ref<HTMLSpanElement>();
  const popover = ref<HTMLDivElement>();
  const popper = ref<Popper>();
  onMounted(() => {
    popper.value = new Popper(target.value!, popover.value!);
  });

  const visible = ref(false);
  watch(
    visible,
    () => popper.value?.scheduleUpdate(),
  );

  const hide = () => visible.value = false;
  const show = () => visible.value = true;
  const toggleVisibility = () => visible.value = !visible.value;
</script>

<style lang="scss" scoped>
  @use "@/sass/variables";

  .info-popover {
    display: inline-block;
  }

  .panel {
    background: variables.$white;
    z-index: 1;
    position: absolute;
    font-weight: normal;
  }

  .panel-block {
    display: block;
  }

  .close-icon {
    float: right;
    margin-left: 0.25em;
    margin-right: -0.25em;
  }

  .icon-center {
    cursor: pointer;
    line-height: 1em;
    vertical-align: bottom; // Dit geeft de beste verticale alignment, kennelijk...
  }
</style>
